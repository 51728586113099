import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import UserDataRedirector from '../routes/redirectors/UserData';
import { getRootLink, PAGES_ROOTS } from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate.jsx';
import BackdropLoading from '../components/Loading/BackdropLoading';
import Card from '../features/ui/applicationShell/containers/RestrictedAccessCard';
import useDidMount from '../hooks/useDidMount';
import { APPLICATION_STATUSES } from '../features/ui/applicationShell/helpers/constants/application';
import { selectApplicationStatus } from '../features/ui/applicationShell/applicationShellSelectors';
import { selectIsLoaded } from '../features/user/userSelectors';

const RestrictedAccess = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isLoaded = useSelector(selectIsLoaded);
  const applicationStatus = useSelector(selectApplicationStatus);

  useEffect(() => {
    if (applicationStatus === APPLICATION_STATUSES.AVAILABLE) {
      history.push(getRootLink(PAGES_ROOTS.fields));
    }
  }, [applicationStatus, history]);

  useDidMount(() => {
    document.title = getDocumentTitle(t('restricted-access.title'));
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <BaseTemplate route={PAGES_ROOTS.restrictedAccess}>
          {isLoaded && <Card key="panel" />}
          {!isLoaded && <BackdropLoading key="loading" />}
        </BaseTemplate>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default RestrictedAccess;
