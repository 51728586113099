import { combineReducers } from 'redux';
import { AnyAction } from '@reduxjs/toolkit';

import fieldProfiler from '../../features/ui/fieldProfiler/fieldProfilerSlice';
import fieldsList from '../../features/ui/fieldsList/fieldsListSlice';
import notifications from '../../features/notifications/notificationsSlice';
import popups from '../../features/ui/popups/popupsSlice';
import createMultiLayerAnalysis from '../../features/ui/createMultiLayerAnalysis/createMultiLayerAnalysisSlice';
import createSingleLayerAnalysis from '../../features/ui/createSingleLayerAnalysis/createSingleLayerAnalysisSlice';
import createFieldPotentialZones from '../../features/ui/createFieldPotentialZones/createFieldPotentialZonesSlice';
import createStabilityZones from '../../features/ui/createStabilityZones/createStabilityZonesSlice';
import createField from '../../features/ui/createField/createFieldSlice';
import jdProfile from '../../features/jdProfile/jdProfileSlice';
import jdFields from '../../features/jdFields/jdFieldsSlice';
import jdOrganizations from '../../features/jdOrganizations/jdOrganizationsSlice';
import jdWorkPlan from '../../features/ui/jdWorkPlan/jdWorkPlanSlice';
import jdExport from '../../features/ui/jdExport/jdExportSlice';
import jdImport from '../../features/ui/jdImport/jdImportSlice';
import jdIntegration from '../../features/ui/jdIntegration/jdIntegrationSlice';
import labels from '../../features/labels/labelsSlice';
import subscription from '../../features/subscription/subscriptionSlice';
import crossLayer from '../../features/ui/crossLayer/crossLayerSlice';
import cloneZonesMap from '../../features/ui/cloneZonesMap/cloneZonesMapSlice';
import uploadData from '../../features/ui/uploadData/uploadDataSlice';
import zonesMaps from '../../features/ui/zonesMaps/zonesMapsSlice';
import zonesMap from '../../features/ui/zonesMap/zonesMapSlice';
import exportData from '../../features/exportData/exportDataSlice';
import downloadFiles from '../../features/ui/downloadFiles/downloadFilesSlice';
import userCreditsUsage from '../../features/ui/userCreditsUsage/userCreditsUsageSlice';
import createThreeDMap from '../../features/ui/createThreeDMap/createThreeDMapSlice';
import smartSampling from '../../features/ui/smartSampling/smartSamplingSlice';
import drawManuallyWorkflow from '../../features/ui/drawManuallyWorkflow/drawManuallyWorkflowSlice';
import field from '../../features/field/fieldSlice';
import createEquationBasedAnalysis from '../../features/ui/createEquationBasedAnalysis/createEquationBasedAnalysisSlice';
import equations from '../../features/equations/equationsSlice';
import compareLayers from '../../features/ui/compareLayers/compareLayersSlice';
import fieldWorkflow from '../../features/ui/fieldWorkflow/fieldWorkflowSlice';
import planetMetadata from '../../features/planetMetadata/planetMetadataSlice';
import zonesOps from '../../features/ui/zonesOps/zonesOpsSlice';
import user, { signOut } from '../../features/user/userSlice';
import colorSchemas from '../../features/colorSchemas/colorSchemasSlice';
import userSettings from '../../features/ui/userSettings/userSettingsSlice';
import createAnalysis from '../../features/createAnalysis/createAnalysisSlice';
import userFarms from '../../features/ui/userFarms/userFarmsSlice';
import mapView from '../../features/ui/mapView/mapViewSlice';
import { emptyAPI } from '../../features/emptyApi/emptyAPI';
import cleanCalibrate from '../../features/ui/cleanCalibrate/cleanCalibrateSlice';
import overlayLayers from '../../features/ui/overlayLayers/overlayLayersSlice';
import batchAnalytics from '../../features/ui/batchAnalytics/batchAnalyticsSlice';
import createBatchEquationBasedAnalysis from '../../features/ui/createBatchEquationBasedAnalysis/createBatchEquationBasedAnalysisSlice';
import assets from '../../features/assets/assetsSlice';

const combinedReducer = combineReducers({
  user,
  colorSchemas,
  fieldsList,
  field,
  assets,
  labels,
  jdProfile,
  jdFields,
  jdOrganizations,
  jdWorkPlan,
  jdExport,
  jdImport,
  jdIntegration,
  notifications,
  popups,
  createAnalysis,
  subscription,
  zonesMaps,
  exportData,
  planetMetadata,
  equations,
  [emptyAPI.reducerPath]: emptyAPI.reducer,
  // ui
  batchAnalytics,
  createBatchEquationBasedAnalysis,
  fieldProfiler,
  mapView,
  uploadData,
  compareLayers,
  fieldWorkflow,
  zonesMap,
  userFarms,
  userSettings,
  zonesOps,
  createMultiLayerAnalysis,
  createSingleLayerAnalysis,
  createFieldPotentialZones,
  createStabilityZones,
  userCreditsUsage,
  createEquationBasedAnalysis,
  crossLayer,
  drawManuallyWorkflow,
  cloneZonesMap,
  createField,
  createThreeDMap,
  downloadFiles,
  smartSampling,
  cleanCalibrate,
  overlayLayers,
});

const applicationReducer = (state: typeof combinedReducer, action: AnyAction) => {
  let resultState = state;

  // pass exactly undefined will reset all parts of Redux state
  if (action.type === signOut.fulfilled.type) {
    resultState = undefined as any;
  }

  return combinedReducer(resultState as any, action);
};

export default applicationReducer;
