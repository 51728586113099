import {
  useEffect,
  useState,
} from 'react';
import { useAppDispatch } from '../../../../app/store/helpers/functions';
import { errorNotify } from '../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../helpers/functions/utils/errorHandling';

export default function useCountriesList() {
  const [countries, setCountries] = useState({});
  const [countriesLoading, setCountriesLoading] = useState(false);

  const dispatch = useAppDispatch();

  const loadCountriesList = async () => {
    const response = await fetch('/assets/countries.json');
    const countriesList = await response.json();
    return countriesList;
  };

  useEffect(() => {
    setCountriesLoading(true);

    loadCountriesList()
      .then((countriesList) => {
        setCountries(countriesList);
      })
      .catch((error) => {
        errorNotify({
          error: new CustomError(
            '[Additional User Info] Unable to fetch the list of countries.',
            {
              cause: error,
            },
          ),
          dispatch,
        });
      })
      .finally(() => {
        setCountriesLoading(false);
      });
  }, [dispatch]);

  return {
    countries,
    countriesLoading,
  };
}
