import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import UserDataRedirector from '../routes/redirectors/UserData';
import { getRootLink, PAGES_ROOTS } from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import Panel from '../features/ui/planSubscription/containers/Panel';
import useDidMount from '../hooks/useDidMount';
import BaseTemplate from './BaseTemplate.jsx';
import useSubscriptionAvailable from '../hooks/useSubscriptionAvailable';

const PlanSubscription = () => {
  const { t } = useTranslation();
  const isPageAvailable = useSubscriptionAvailable();

  useDidMount(() => {
    document.title = getDocumentTitle(t('plan-subscription.header'));
  });

  if (!isPageAvailable) {
    return (
      <Redirect to={getRootLink(PAGES_ROOTS.restrictedAccess)} />
    );
  }

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.planSubscription}
          headerProps={{
            text: t('plan-subscription.header'),
          }}
        >
          <Panel key="panel" />
        </BaseTemplate>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default PlanSubscription;
