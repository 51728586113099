export const ACTIONS_MAP = {
  SENTINEL_LANDSAT_ACTIVATION: 'SENTINEL_LANDSAT_ACTIVATION',
  PLANET_ACTIVATION: 'PLANET_ACTIVATION',
  SINGLE_PLANET_IMAGE_ORDER: 'SINGLE_PLANET_IMAGE_ORDER',
  ISOXML_EXPORT: 'ISOXML_EXPORT',
  SHP_EXPORT: 'SHP_EXPORT',
  TOPOGRAPHY_DATASET: 'TOPOGRAPHY_DATASET',
  SOIL_SENSOR_DATASET: 'SOIL_SENSOR_DATASET',
  SOIL_DATASET: 'SOIL_DATASET',
  YIELD_DATASET: 'YIELD_DATASET',
  YIELD_CALIBRATION: 'YIELD_CALIBRATION',
  AS_APPLIED_DATASET: 'AS_APPLIED_DATASET',
  SINGLE_LAYER_ZONES_MAPS: 'SINGLE_LAYER_ZONES_MAPS',
  MULTI_YEAR_ZONES_MAPS: 'MULTI_YEAR_ZONES_MAPS',
  STABILITY_ZONES_MAPS: 'STABILITY_ZONES_MAPS',
  MULTI_LAYER_ZONES_MAPS: 'MULTI_LAYER_ZONES_MAPS',
  CROSS_LAYER_ZONES_MAPS: 'CROSS_LAYER_ZONES_MAPS',
  EQUATION_PIXELS_MAPS: 'EQUATION_PIXELS_MAPS',
  EQUATION_ZONES_MAPS: 'EQUATION_ZONES_MAPS',
  GEO_MAP: 'GEO_MAP',
  GEO_FEATURES: 'GEO_FEATURES',
  USERS_ADDITIONAL_ACCOUNT: 'USERS_ADDITIONAL_ACCOUNT',
  FIELD_PIN: 'FIELD_NOTE',
  PIN_COMMENT: 'NOTE_COMMENT',
  PIN_PHOTO: 'NOTE_PHOTO',
  COMMENT_PHOTO: 'COMMENT_PHOTO',
  FIELD_ACTIVATION: 'FIELD_ACTIVATION',
  FIELD_DELETION: 'FIELD_DELETION',
  MAP_3D: 'MAP_3D',
  SOURCE_TOPOGRAPHY_MAP: 'SOURCE_TOPOGRAPHY_MAP',
  SOURCE_FIELD_SATELLITE_IMAGE: 'SOURCE_FIELD_SATELLITE_IMAGE',
  SOURCE_VECTOR_ANALYSIS_MAP: 'SOURCE_VECTOR_ANALYSIS_MAP',
  SOURCE_YIELD_DATASET: 'SOURCE_YIELD_DATASET',
  SOURCE_SOIL_DATASET: 'SOURCE_SOIL_DATASET',
  SOURCE_AS_APPLIED_DATASET: 'SOURCE_AS_APPLIED_DATASET',
  PDF_REPORT: 'PDF_REPORT',
  DRAWN_ZONES_MAPS: 'DRAWN_ZONES_MAPS',
};

export const ACTION_TO_I18N_KEY_MAP = {
  [ACTIONS_MAP.SENTINEL_LANDSAT_ACTIVATION]: 'credits-usage.operations.sentinel-landsat-activation',
  [ACTIONS_MAP.PLANET_ACTIVATION]: 'credits-usage.operations.planet-activation',
  [ACTIONS_MAP.SINGLE_PLANET_IMAGE_ORDER]: 'credits-usage.operations.single-planet-image-order',
  [ACTIONS_MAP.ISOXML_EXPORT]: 'credits-usage.operations.isoxml-export',
  [ACTIONS_MAP.SHP_EXPORT]: 'credits-usage.operations.shp-export',
  [ACTIONS_MAP.TOPOGRAPHY_DATASET]: 'credits-usage.operations.topography-dataset',
  [ACTIONS_MAP.SOIL_SENSOR_DATASET]: 'credits-usage.operations.soil-sensor-dataset',
  [ACTIONS_MAP.SOIL_DATASET]: 'credits-usage.operations.soil-dataset',
  [ACTIONS_MAP.YIELD_DATASET]: 'credits-usage.operations.yield-dataset',
  [ACTIONS_MAP.YIELD_CALIBRATION]: 'credits-usage.operations.yield-calibration',
  [ACTIONS_MAP.AS_APPLIED_DATASET]: 'credits-usage.operations.as-applied-dataset',
  [ACTIONS_MAP.SINGLE_LAYER_ZONES_MAPS]: 'credits-usage.operations.single-layer-zones-maps',
  [ACTIONS_MAP.MULTI_YEAR_ZONES_MAPS]: 'credits-usage.operations.multi-year-zones-maps',
  [ACTIONS_MAP.STABILITY_ZONES_MAPS]: 'credits-usage.operations.stability-zones-maps',
  [ACTIONS_MAP.MULTI_LAYER_ZONES_MAPS]: 'credits-usage.operations.multi-layer-zones-maps',
  [ACTIONS_MAP.CROSS_LAYER_ZONES_MAPS]: 'credits-usage.operations.cross-layer-zones-maps',
  [ACTIONS_MAP.EQUATION_PIXELS_MAPS]: 'credits-usage.operations.equation-pixels-maps',
  [ACTIONS_MAP.EQUATION_ZONES_MAPS]: 'credits-usage.operations.equation-zones-maps',
  [ACTIONS_MAP.GEO_MAP]: 'credits-usage.operations.geo-map',
  [ACTIONS_MAP.GEO_FEATURES]: 'credits-usage.operations.geo-features',
  [ACTIONS_MAP.FIELD_PIN]: 'credits-usage.operations.scouting-pin',
  [ACTIONS_MAP.PIN_COMMENT]: 'credits-usage.operations.scouting-comment',
  [ACTIONS_MAP.PIN_PHOTO]: 'credits-usage.operations.scouting-photo',
  [ACTIONS_MAP.COMMENT_PHOTO]: 'credits-usage.operations.scouting-photo',
  [ACTIONS_MAP.USERS_ADDITIONAL_ACCOUNT]: 'credits-usage.operations.users-additional-account',
  [ACTIONS_MAP.FIELD_ACTIVATION]: 'credits-usage.operations.field-activation',
  [ACTIONS_MAP.FIELD_DELETION]: 'credits-usage.operations.field-deletion',
  [ACTIONS_MAP.MAP_3D]: 'credits-usage.operations.map-3d',
  [ACTIONS_MAP.SOURCE_TOPOGRAPHY_MAP]: 'credits-usage.operations.source-topography-map',
  [ACTIONS_MAP.SOURCE_FIELD_SATELLITE_IMAGE]: 'credits-usage.operations.source-field-satellite-image',
  [ACTIONS_MAP.SOURCE_VECTOR_ANALYSIS_MAP]: 'credits-usage.operations.source-vector-analysis-map',
  [ACTIONS_MAP.SOURCE_YIELD_DATASET]: 'credits-usage.operations.source-yield-dataset',
  [ACTIONS_MAP.SOURCE_SOIL_DATASET]: 'credits-usage.operations.source-soil-dataset',
  [ACTIONS_MAP.SOURCE_AS_APPLIED_DATASET]: 'credits-usage.operations.source-as-applied-dataset',
  [ACTIONS_MAP.PDF_REPORT]: 'credits-usage.operations.pdf-report',
  [ACTIONS_MAP.DRAWN_ZONES_MAPS]: 'credits-usage.operations.drawn-zones-maps',
};

export const ACTIONS_PAGE_SIZE = 500;

export const MAX_ACTIONS_PAGE_SIZE = 1000;
