export const SUPPORTED_COUNTRIES: Record<string, string> = {
  DEU: 'DEU',
  ESP: 'ESP',
  FRA: 'FRA',
  PRT: 'PRT',
  ROU: 'ROU',
  BGR: 'BGR',
  UKR: 'UKR',
  BEL: 'BEL',
  NLD: 'NLD',
  AUT: 'AUT',
  SRB: 'SRB',
  HUN: 'HUN',
  POL: 'POL',
  CZE: 'CZE',
  SVK: 'SVK',
  MDA: 'MDA',
  ITA: 'ITA',
  DNK: 'DNK',
  SWE: 'SWE',
  FIN: 'FIN',
  EST: 'EST',
  LVA: 'LVA',
  LTU: 'LTU',
  NOR: 'NOR',
  GBR: 'GBR',
  IRL: 'IRL',
  GRC: 'GRC',
  LUX: 'LUX',
  ZAF: 'ZAF',
  ARG: 'ARG',
  USA: 'USA',
  CAN: 'CAN',
  BRA: 'BRA',
  CHE: 'CHE',
  KAZ: 'KAZ',
  SVN: 'SVN',
  HRV: 'HRV',
  AUS: 'AUS',
  COL: 'COL',
  ECU: 'ECU',
  CRI: 'CRI',
  SLV: 'SLV',
  GTM: 'GTM',
  PAN: 'PAN',
  CHL: 'CHL',
  MEX: 'MEX',
  PRI: 'PRI',
  HND: 'HND',
  PRY: 'PRY',
  URY: 'URY',
  NZL: 'NZL',
  KGZ: 'KGZ',
  TUR: 'TUR',
  ALB: 'ALB',
  AND: 'AND',
  BIH: 'BIH',
  XKX: 'XKX',
  LIE: 'LIE',
  MNE: 'MNE',
  MKD: 'MKD',
  SMR: 'SMR',
};

export const FREE_TRIAL_SUPPORTED_COUNTRIES: Record<string, string> = {
  USA: 'USA',
  CAN: 'CAN',
  BRA: 'BRA',
  AUT: 'AUT',
  BEL: 'BEL',
  BGR: 'BGR',
  HRV: 'HRV',
  CYP: 'CYP',
  CZE: 'CZE',
  DNK: 'DNK',
  EST: 'EST',
  FIN: 'FIN',
  FRA: 'FRA',
  DEU: 'DEU',
  GRC: 'GRC',
  HUN: 'HUN',
  IRL: 'IRL',
  ITA: 'ITA',
  LVA: 'LVA',
  LTU: 'LTU',
  LUX: 'LUX',
  MLT: 'MLT',
  NLD: 'NLD',
  POL: 'POL',
  PRT: 'PRT',
  ROU: 'ROU',
  SVK: 'SVK',
  SVN: 'SVN',
  ESP: 'ESP',
  SWE: 'SWE',
  UKR: 'UKR',
  SRB: 'SRB',
  MDA: 'MDA',
  NOR: 'NOR',
  GBR: 'GBR',
  CHE: 'CHE',
  LIE: 'LIE',
  MNE: 'MNE',
  MKD: 'MKD',
  SMR: 'SMR',
  AUS: 'AUS',
  NZL: 'NZL',
};
