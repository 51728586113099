import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import {
  FREE_TRIAL_SUPPORTED_COUNTRIES,
  SUPPORTED_COUNTRIES,
} from '../constants/countries';
import { HelperTextType } from '../constants/helperTexts';
import { getDemoFormLink } from '../../../../../helpers/functions/utils/appConfig';

export const getCountryHelperTexts = (country: string) => {
  if (!country) {
    return [];
  }

  const isCountrySupported = SUPPORTED_COUNTRIES[country];

  if (!isCountrySupported) {
    return [
      {
        label: i18n.t('authentication.additional-user-data.validation.country-not-supported'),
        type: HelperTextType.error,
      },
      {
        label: (<Trans i18nKey="authentication.additional-user-data.validation.request-to-add-your-country">
          {''}
          <a className='link' href={getDemoFormLink()} target="_blank">
            Drop us a request
          </a>
          to add your country
        </Trans>),
        type: HelperTextType.info,
      },
    ];
  }

  const isFreeTrialSupported = FREE_TRIAL_SUPPORTED_COUNTRIES[country];

  if (!isFreeTrialSupported) {
    return [
      {
        label: i18n.t('authentication.additional-user-data.validation.free-trial-not-available'),
        type: HelperTextType.info,
      },
    ];
  }

  return [];
};
