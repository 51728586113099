import { HELPER_TEXTS } from './helperTexts';

export const LOG_IN_ERRORS = [
  {
    regexp: /user.*not.*exist/i,
    helperText: HELPER_TEXTS.invalidEmail,
    field: 'email',
  },
  {
    regexp: /user.*not.*confirmed/i,
    helperText: HELPER_TEXTS.notVerifiedEmail,
    field: 'email',
  },
  {
    regexp: /validation.*error.*username/i,
    helperText: HELPER_TEXTS.invalidEmail,
    field: 'email',
  },
  {
    regexp: /incorrect.*password/i,
    helperText: HELPER_TEXTS.invalidPassword,
    field: 'password',
  },
];

export const SIGN_UP_ERRORS = [
  {
    regexp: /email.*exists/i,
    helperText: HELPER_TEXTS.emailExists,
    field: 'email',
  },
  {
    regexp: /invalid.*email.*format/i,
    helperText: HELPER_TEXTS.invalidEmail,
    field: 'email',
  },
  {
    regexp: /username.*failed.*constraint/i,
    helperText: HELPER_TEXTS.invalidEmail,
    field: 'email',
  },
  {
    regexp: /password.*not.*long/i,
    helperText: HELPER_TEXTS.passwordLength,
    field: 'password',
  },
  {
    regexp: /must.*have.*numeric/i,
    helperText: HELPER_TEXTS.passwordNumeric,
    field: 'password',
  },
  {
    regexp: /invalid.*phone.*format/i,
    helperText: HELPER_TEXTS.invalidPhone,
    field: 'phone',
  },
];

export const FORGOT_PASSWORD_ERRORS = [
  {
    regexp: /username.*not.*found/i,
    helperText: HELPER_TEXTS.invalidEmail,
  },
  {
    regexp: /validation.*error.*username/i,
    helperText: HELPER_TEXTS.invalidEmail,
  },
  {
    regexp: /no.*verified.*email/i,
    helperText: HELPER_TEXTS.notVerifiedEmail,
  },
];

export const RECOVER_PASSWORD_ERRORS = [
  {
    regexp: /request.*code.*again/i,
    helperText: HELPER_TEXTS.expiredCode,
  },
];

export const USER_DATA_ERRORS = [
  {
    regexp: /country/i,
    helperTextI18NKey: 'authentication.additional-user-data.errors.invalid-country',
    field: 'country',
  },
];
