import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  NavLink,
  Link,
  useLocation,
} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AssignmentSharpIcon from '@material-ui/icons/AssignmentSharp';
import MapSharpIcon from '@material-ui/icons/MapSharp';
import LibraryAddSharpIcon from '@material-ui/icons/LibraryAddSharp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import HomeIcon from '@material-ui/icons/Home';

import Tooltip from '../../../../../components/Tooltip';
import DrawerTooltip from '../../../../../components/Tooltip/Tooltips/DrawerTooltip';
import LogoBig from '../../../../../../public/assets/logo-big.svg';
import LogoSmall from '../../../../../../public/assets/logo-small.svg';
import ExportIcon from '../../../../../components/Icons/export';
import ImportIcon from '../../../../../components/Icons/import';
import OperationsIcon from '../../../../../components/Icons/operations';
import ExpandMenuIcon from '../../../../../components/Icons/expand-menu.svg';
import CollapseMenuIcon from '../../../../../components/Icons/collapse-menu.svg';
import {
  PAGES_ROOTS,
  getRootLink,
} from '../../../../../helpers/navigation';
import { triggerMapResize } from '../../../../../helpers/mapbox/map';
import { APPLICATION_STATUSES } from '../../helpers/constants/application';
import { isAccessible } from '../../../../../helpers';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import {
  isJohnDeereExportWorkflowEnabled,
  isJohnDeereImportWorkflowEnabled,
} from '../../../../../helpers/functions/utils/appConfig';
import { getCustomerPortalLink } from '../../../../../helpers/functions/utils/vendorConfigs/stripe';
import { hasStripePayment } from '../../../../user/helpers/functions/order';

import './index.scss';

const LOCAL_STORAGE_TOGGLE_KEY = 'drawer';
const LOCAL_STORAGE_SUB_MENUS_KEY = 'drawer_sub_menus';

const isFieldsLinkActive = (pathname) => {
  return pathname.startsWith(PAGES_ROOTS.fields)
  || pathname.startsWith(PAGES_ROOTS.field)
  || pathname.startsWith(PAGES_ROOTS.createField)
  || pathname.startsWith(PAGES_ROOTS.compareLayers);
};
const isZonesMapsLinkActive = (pathname) => {
  return pathname.startsWith(PAGES_ROOTS.zonesMaps)
  || pathname.startsWith(`${PAGES_ROOTS.zonesMap}/`)
  || pathname.startsWith(PAGES_ROOTS.zonesOps);
};

const getTooltipConfig = (t, type, currentPath, notifications, {
  email = '',
  isApplicationBlocked,
  displayOrganizations,
  displaySubscription,
  orders,
} = {}) => {
  switch (type) {
    case 'home':
      return {
        type: 'single-item',
        title: t('general.navigation.homepage'),
        active: currentPath === PAGES_ROOTS.homepage,
      };
    case 'fields':
      return {
        type: 'single-item',
        title: t('general.navigation.field-profiler'),
        active: isFieldsLinkActive(currentPath),
      };
    case 'zones-maps':
      return {
        type: 'single-item',
        title: t('general.navigation.zones-maps'),
        active: isZonesMapsLinkActive(currentPath),
      };
    case 'batch-analytics':
      return {
        type: 'single-item',
        title: t('general.navigation.batch-analytics'),
        active: currentPath.startsWith(PAGES_ROOTS.batchAnalytics),
      };
    case 'operations':
      return {
        type: 'single-item',
        title: t('general.navigation.operations'),
        active: currentPath.startsWith(PAGES_ROOTS.operations),
      };
    case 'user':
      return {
        type: 'list',
        header: email,
        items: [
          ...(
            isApplicationBlocked || !displayOrganizations
              ? []
              : [
                {
                  title: t('general.navigation.organizations'),
                  link: getRootLink(PAGES_ROOTS.userOrganizations),
                  active: currentPath.startsWith(PAGES_ROOTS.userOrganizations),
                },
              ]
          ),
          ...(
            displaySubscription
              ? [
                (
                  hasStripePayment(orders)
                    ? {
                      title: t('general.navigation.manage-my-plan'),
                      href: getCustomerPortalLink(),
                      target: '_blank',
                    }
                    : {
                      title: t('general.navigation.manage-my-plan'),
                      link: getRootLink(PAGES_ROOTS.planSubscription),
                      active: currentPath.startsWith(PAGES_ROOTS.planSubscription),
                    }
                ),
                {
                  title: t('general.navigation.credits-usage'),
                  link: getRootLink(PAGES_ROOTS.userCreditsUsage),
                  active: currentPath.startsWith(PAGES_ROOTS.userCreditsUsage),
                },
              ]
              : []
          ),
          ...(isApplicationBlocked
            ? []
            : [
              {
                title: t('general.navigation.farms'),
                link: getRootLink(PAGES_ROOTS.userFarms),
                active: currentPath.startsWith(PAGES_ROOTS.userFarms),
              },
              {
                title: t('general.navigation.integrations'),
                link: getRootLink(PAGES_ROOTS.userIntegrations),
                active: currentPath.startsWith(PAGES_ROOTS.userIntegrations),
                notification: notifications.user?.subMenus.includes('integrations')
                  ? notifications.user?.type
                  : undefined,
              },
              {
                title: t('general.navigation.settings'),
                link: getRootLink(PAGES_ROOTS.userSettings),
                active: currentPath.startsWith(PAGES_ROOTS.userSettings),
              },
            ]
          ),
        ],
      };
    case 'upload-data':
      return {
        type: 'list',
        header: t('general.navigation.import-data'),
        items: [
          {
            title: t('general.navigation.upload-files'),
            link: getRootLink(PAGES_ROOTS.upload),
            active: currentPath.startsWith(PAGES_ROOTS.upload)
              && currentPath !== PAGES_ROOTS.uploadDataJohnDeere,
          },
          ...(
            isJohnDeereImportWorkflowEnabled()
              ? [
                {
                  title: t('general.navigation.upload-from-john-deere'),
                  link: getRootLink(PAGES_ROOTS.uploadDataJohnDeere),
                  active: currentPath.startsWith(PAGES_ROOTS.uploadDataJohnDeere),
                },
              ]
              : []
          ),
        ],
      };
    case 'export-data':
      return {
        type: 'list',
        header: t('general.navigation.export-data'),
        items: [
          {
            title: t('general.navigation.download-files'),
            link: getRootLink(PAGES_ROOTS.exportData),
            active: currentPath.startsWith(PAGES_ROOTS.exportData)
                && currentPath !== PAGES_ROOTS.exportDataJohnDeere,
          },
          ...(
            isJohnDeereExportWorkflowEnabled()
              ? [
                {
                  title: t('general.navigation.export-to-john-deere'),
                  link: getRootLink(PAGES_ROOTS.exportDataJohnDeere),
                  active: currentPath.startsWith(PAGES_ROOTS.exportDataJohnDeere),
                },
              ]
              : []
          ),
        ],
      };
    default:
      return null;
  }
};

const AppDrawer = ({
  email,
  displayOrganizations,
  displaySubscription,
  orders,
  applicationStatus,
  notifications,
  onListItemClick,
}) => {
  const { t } = useTranslation();
  const currentPath = useLocation().pathname.slice(1);
  const [open, setOpen] = useLocalStorage(LOCAL_STORAGE_TOGGLE_KEY, true);
  const [openSubMenus, setOpenSubMenus] = useLocalStorage(LOCAL_STORAGE_SUB_MENUS_KEY, []);

  const isFeatureAccessible = (minStatus) => {
    return isAccessible(applicationStatus, minStatus);
  };

  const handleToggleButtonClick = () => {
    // need to trigger after transition finished
    triggerMapResize(225);
    setOpen(!open);
  };

  const handleSubMenuClick = (menu) => {
    if (!open) {
      return;
    }

    if (openSubMenus.includes(menu)) {
      setOpenSubMenus(openSubMenus.filter((openSubMenu) => openSubMenu !== menu));
    } else {
      setOpenSubMenus([...openSubMenus, menu]);
    }
  };

  const isOpenSubMenu = (menu) => {
    return openSubMenus.includes(menu);
  };

  const getChevron = (isOpen) => {
    return isOpen
      ? <ExpandMore className="list-item__chevron"/>
      : <NavigateNext className="list-item__chevron"/>;
  };
  const isUploadDataSubMenuOpened = open && isOpenSubMenu('upload-data');
  const isExportDataSubMenuOpened = open && isOpenSubMenu('export-data');
  const isUserSubMenuOpened = open && isOpenSubMenu('user');

  const getParentSubMenuClasses = (menu, isCurrentSubMenu) => {
    return clsx('list-item', {
      'list-item_opened': isCurrentSubMenu || isOpenSubMenu(menu),
      'list-item_selected': isCurrentSubMenu && !open,
      'list-item_child-selected': isCurrentSubMenu && open && !isOpenSubMenu(menu),
    });
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx('drawer', {
        drawer_open: open,
        drawer_close: !open,
      })}
      classes={{
        paper: clsx('paper', {
          drawer_open: open,
          drawer_close: !open,
        }),
      }}
    >
      <Link
        to={getRootLink(PAGES_ROOTS.homepage)}
        className="drawer__logo"
      >
        { open ? <LogoBig /> : <LogoSmall /> }
      </Link>
      {
        isFeatureAccessible(APPLICATION_STATUSES.AVAILABLE)
        && (
          <Fragment>
            <List disablePadding>
              <ListItem
                button
                component={NavLink}
                to={getRootLink(PAGES_ROOTS.homepage)}
                activeClassName="list-item_selected"
                classes={{
                  root: 'list-item',
                  gutters: 'list-item__gutters',
                }}
                isActive={(_match, location) => {
                  return location.pathname === '/';
                }}
              >
                <ListItemIcon classes={{
                  root: 'list-item__icon',
                }}
                >
                  <Tooltip
                    placement="left-start"
                    tooltip={open ? '' : <DrawerTooltip config={getTooltipConfig(t, 'home', currentPath)} />}
                    classes={{
                      tooltip: 'list-item__tooltip',
                      arrow: 'list-item__tooltip-arrow',
                    }}
                  >
                    <HomeIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className="list-item__text"
                  primary={t('general.navigation.homepage')}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to={getRootLink(PAGES_ROOTS.fields)}
                activeClassName="list-item_selected"
                classes={{
                  root: 'list-item',
                  gutters: 'list-item__gutters',
                }}
                isActive={(_match, location) => {
                  const pathname = location.pathname.slice(1);

                  return isFieldsLinkActive(pathname);
                }}
                onClick={(e) => onListItemClick(e, { page: PAGES_ROOTS.fields })}
              >
                <ListItemIcon classes={{
                  root: 'list-item__icon',
                }}
                >
                  <Tooltip
                    placement="left-start"
                    tooltip={open ? '' : <DrawerTooltip config={getTooltipConfig(t, 'fields', currentPath)} />}
                    classes={{
                      tooltip: 'list-item__tooltip',
                      arrow: 'list-item__tooltip-arrow',
                    }}
                  >
                    <AssignmentSharpIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className="list-item__text"
                  primary={t('general.navigation.field-profiler')}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to={getRootLink(PAGES_ROOTS.zonesMaps)}
                activeClassName="list-item_selected"
                classes={{
                  root: 'list-item',
                  gutters: 'list-item__gutters',
                }}
                isActive={(_match, location) => {
                  const pathname = location.pathname.slice(1);

                  return isZonesMapsLinkActive(pathname);
                }}
                onClick={(e) => onListItemClick(e, { page: PAGES_ROOTS.zonesMaps })}
              >
                <ListItemIcon classes={{
                  root: 'list-item__icon',
                }}
                >
                  <Tooltip
                    placement="left-start"
                    tooltip={open ? '' : <DrawerTooltip config={getTooltipConfig(t, 'zones-maps', currentPath)} />}
                    classes={{
                      tooltip: 'list-item__tooltip',
                      arrow: 'list-item__tooltip-arrow',
                    }}
                  >
                    <MapSharpIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className="list-item__text"
                  primary={t('general.navigation.zones-maps')}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to={getRootLink(PAGES_ROOTS.batchAnalytics)}
                activeClassName="list-item_selected"
                classes={{
                  root: 'list-item',
                  gutters: 'list-item__gutters',
                }}
                isActive={(_match, location) => {
                  const pathname = location.pathname.slice(1);
                  return pathname === PAGES_ROOTS.batchAnalytics;
                }}
                onClick={(e) => onListItemClick(e, { page: PAGES_ROOTS.batchAnalytics })}
              >
                <ListItemIcon classes={{
                  root: 'list-item__icon',
                }}
                >
                  <Tooltip
                    placement="left-start"
                    tooltip={open ? '' : <DrawerTooltip config={getTooltipConfig(t, 'batch-analytics', currentPath)} />}
                    classes={{
                      tooltip: 'list-item__tooltip',
                      arrow: 'list-item__tooltip-arrow',
                    }}
                  >
                    <LibraryAddSharpIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className="list-item__text"
                  primary={t('general.navigation.batch-analytics')}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
              </ListItem>
              <ListItem
                button
                onClick={() => handleSubMenuClick('upload-data')}
                classes={{
                  root: getParentSubMenuClasses('upload-data', currentPath.startsWith(PAGES_ROOTS.upload)),
                  gutters: 'list-item__gutters',
                }}
              >
                <ListItemIcon classes={{
                  root: 'list-item__icon',
                }}
                >
                  <Tooltip
                    interactive
                    placement="left-start"
                    tooltip={open ? '' : <DrawerTooltip config={getTooltipConfig(t, 'upload-data', currentPath, notifications)} />}
                    classes={{
                      tooltip: 'list-item__tooltip',
                      arrow: 'list-item__tooltip-arrow',
                    }}
                  >
                    <ImportIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  title={t('general.navigation.import-data')}
                  className="list-item__text"
                  primary={t('general.navigation.import-data')}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
                {getChevron(isUploadDataSubMenuOpened)}
              </ListItem>
              <Collapse
                in={isUploadDataSubMenuOpened}
                timeout="auto"
                unmountOnExit
                classes={{
                  container: 'list-item-group_opened',
                }}
              >
                <List disablePadding>
                  <ListItem
                    button
                    component={NavLink}
                    to={getRootLink(PAGES_ROOTS.upload)}
                    activeClassName="list-item_selected"
                    classes={{
                      root: 'list-item',
                      gutters: 'list-item__gutters',
                    }}
                    isActive={(_match, location) => {
                      const pathname = location.pathname.slice(1);
                      return pathname.startsWith(PAGES_ROOTS.upload)
                      && pathname !== PAGES_ROOTS.uploadDataJohnDeere;
                    }}
                  >
                    <ListItemIcon classes={{
                      root: 'list-item__icon',
                    }}
                    />
                    <ListItemText
                      className="list-item__text"
                      primary={t('general.navigation.upload-files')}
                      primaryTypographyProps={{
                        variant: 'body2',
                      }}
                    />
                  </ListItem>
                  {
                  isJohnDeereImportWorkflowEnabled()
                  && (
                    <ListItem
                      button
                      component={NavLink}
                      to={getRootLink(PAGES_ROOTS.uploadDataJohnDeere)}
                      activeClassName="list-item_selected"
                      classes={{
                        root: 'list-item',
                        gutters: 'list-item__gutters',
                      }}
                      isActive={(_match, location) => {
                        const pathname = location.pathname.slice(1);
                        return pathname === PAGES_ROOTS.uploadDataJohnDeere;
                      }}
                    >
                      <ListItemIcon classes={{
                        root: 'list-item__icon',
                      }}
                      />
                      <ListItemText
                        className="list-item__text"
                        primary={t('general.navigation.upload-from-john-deere')}
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                      />
                    </ListItem>
                  )
                }
                </List>
              </Collapse>
              <ListItem
                button
                onClick={() => handleSubMenuClick('export-data')}
                classes={{
                  root: getParentSubMenuClasses('export-data', currentPath.startsWith(PAGES_ROOTS.exportData)),
                  gutters: 'list-item__gutters',
                }}
              >
                <ListItemIcon classes={{
                  root: 'list-item__icon',
                }}
                >
                  <Tooltip
                    interactive
                    placement="left-start"
                    tooltip={open ? '' : <DrawerTooltip config={getTooltipConfig(t, 'export-data', currentPath, notifications)} />}
                    classes={{
                      tooltip: 'list-item__tooltip',
                      arrow: 'list-item__tooltip-arrow',
                    }}
                  >
                    <ExportIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  title={t('general.navigation.export-data')}
                  className="list-item__text"
                  primary={t('general.navigation.export-data')}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
                {getChevron(isExportDataSubMenuOpened)}
              </ListItem>
              <Collapse
                in={isExportDataSubMenuOpened}
                timeout="auto"
                unmountOnExit
                classes={{
                  container: 'list-item-group_opened',
                }}
              >
                <List disablePadding>
                  <ListItem
                    button
                    component={NavLink}
                    to={getRootLink(PAGES_ROOTS.exportData)}
                    activeClassName="list-item_selected"
                    classes={{
                      root: 'list-item',
                      gutters: 'list-item__gutters',
                    }}
                    isActive={(_match, location) => {
                      const pathname = location.pathname.slice(1);
                      return pathname.startsWith(PAGES_ROOTS.exportData)
                      && pathname !== PAGES_ROOTS.exportDataJohnDeere;
                    }}
                  >
                    <ListItemIcon classes={{ root: 'list-item__icon' }} />
                    <ListItemText
                      className="list-item__text"
                      primary={t('general.navigation.download-files')}
                      primaryTypographyProps={{
                        variant: 'body2',
                      }}
                    />
                  </ListItem>
                  {
                  isJohnDeereExportWorkflowEnabled()
                  && (
                    <ListItem
                      button
                      component={NavLink}
                      to={getRootLink(PAGES_ROOTS.exportDataJohnDeere)}
                      activeClassName="list-item_selected"
                      classes={{
                        root: 'list-item',
                        gutters: 'list-item__gutters',
                      }}
                      isActive={(_match, location) => {
                        const pathname = location.pathname.slice(1);
                        return pathname === PAGES_ROOTS.exportDataJohnDeere;
                      }}
                    >
                      <ListItemIcon classes={{ root: 'list-item__icon' }} />
                      <ListItemText
                        className="list-item__text"
                        primary={t('general.navigation.export-to-john-deere')}
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                      />
                    </ListItem>
                  )
                }
                </List>
              </Collapse>
              <ListItem
                button
                component={NavLink}
                to={getRootLink(PAGES_ROOTS.operations)}
                activeClassName="list-item_selected"
                classes={{
                  root: 'list-item',
                  gutters: 'list-item__gutters',
                }}
                isActive={(_match, location) => {
                  const pathname = location.pathname.slice(1);

                  return pathname.startsWith(PAGES_ROOTS.operations);
                }}
                onClick={(e) => onListItemClick(e, { page: PAGES_ROOTS.fields })}
              >
                <ListItemIcon classes={{
                  root: 'list-item__icon',
                }}
                >
                  <Tooltip
                    placement="left-start"
                    tooltip={open ? '' : <DrawerTooltip config={getTooltipConfig(t, 'operations', currentPath)} />}
                    classes={{
                      tooltip: 'list-item__tooltip',
                      arrow: 'list-item__tooltip-arrow',
                    }}
                  >
                    <OperationsIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className="list-item__text"
                  primary={t('general.navigation.operations')}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
              </ListItem>
            </List>
            <Divider />
          </Fragment>
        )
      }
      <List disablePadding>
        {
          isFeatureAccessible(APPLICATION_STATUSES.SUBSCRIPTION_AVAILABLE)
          && (
            <Fragment>
              <ListItem
                button
                onClick={() => handleSubMenuClick('user')}
                classes={{
                  root: getParentSubMenuClasses('user', currentPath.startsWith(PAGES_ROOTS.user)),
                  gutters: 'list-item__gutters',
                }}
              >
                <ListItemIcon classes={{
                  root: 'list-item__icon',
                }}
                >
                  <Tooltip
                    interactive
                    placement="left-start"
                    tooltip={open
                      ? ''
                      : (
                        <DrawerTooltip config={getTooltipConfig(t, 'user', currentPath, notifications, {
                          email,
                          isApplicationBlocked: !isFeatureAccessible(APPLICATION_STATUSES.AVAILABLE),
                          displayOrganizations,
                          displaySubscription,
                          orders,
                        })}
                        />
                      )}
                    classes={{
                      tooltip: 'list-item__tooltip',
                      label: 'list-item__tooltip-label',
                      arrow: 'list-item__tooltip-arrow',
                    }}
                  >
                    {
                      notifications.user && !isUserSubMenuOpened
                        && (
                          <div className={clsx('drawer__notification-dot', {
                            'drawer__notification-dot_error': notifications.user.type === 'error',
                          })}
                          >
                          </div>
                        )
                    }
                    <AccountCircleIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  title={email}
                  className="list-item__text"
                  primary={email}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
                {getChevron(isUserSubMenuOpened)}
              </ListItem>
              <Collapse
                in={isUserSubMenuOpened}
                timeout="auto"
                unmountOnExit
                classes={{
                  container: 'list-item-group_opened',
                }}
              >
                <List disablePadding>
                  {
                  (isFeatureAccessible(APPLICATION_STATUSES.AVAILABLE) && displayOrganizations)
                  && (
                    <ListItem
                      button
                      component={NavLink}
                      to={getRootLink(PAGES_ROOTS.userOrganizations)}
                      activeClassName="list-item_selected"
                      classes={{
                        root: 'list-item',
                        gutters: 'list-item__gutters',
                      }}
                      isActive={(_match, location) => {
                        const pathname = location.pathname.slice(1);
                        return pathname === PAGES_ROOTS.userOrganizations;
                      }}
                    >
                      <ListItemIcon classes={{ root: 'list-item__icon' }} />
                      <ListItemText
                        className="list-item__text"
                        primary={t('general.navigation.organizations')}
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                      />
                    </ListItem>
                  )
                }
                  {
                  displaySubscription
                  && (
                    <>
                      {
                        hasStripePayment(orders)
                          ? (
                            <ListItem
                              button
                              component="a"
                              href={getCustomerPortalLink()}
                              target="_blank"
                              classes={{
                                root: 'list-item',
                                gutters: 'list-item__gutters',
                              }}
                            >
                              <ListItemIcon classes={{ root: 'list-item__icon' }} />
                              <ListItemText
                                className="list-item__text"
                                primary={t('general.navigation.manage-my-plan')}
                                primaryTypographyProps={{
                                  variant: 'body2',
                                }}
                              />
                            </ListItem>
                          )
                          : (
                            <ListItem
                              button
                              component={NavLink}
                              to={getRootLink(PAGES_ROOTS.planSubscription)}
                              activeClassName="list-item_selected"
                              classes={{
                                root: 'list-item',
                                gutters: 'list-item__gutters',
                              }}
                              isActive={(_match, location) => {
                                const pathname = location.pathname.slice(1);
                                return pathname === PAGES_ROOTS.planSubscription;
                              }}
                            >
                              <ListItemIcon classes={{ root: 'list-item__icon' }} />
                              <ListItemText
                                className="list-item__text"
                                primary={t('general.navigation.manage-my-plan')}
                                primaryTypographyProps={{
                                  variant: 'body2',
                                }}
                              />
                            </ListItem>
                          )
                      }
                      <ListItem
                        button
                        component={NavLink}
                        to={getRootLink(PAGES_ROOTS.userCreditsUsage)}
                        activeClassName="list-item_selected"
                        classes={{
                          root: 'list-item',
                          gutters: 'list-item__gutters',
                        }}
                        isActive={(_match, location) => {
                          const pathname = location.pathname.slice(1);
                          return pathname === PAGES_ROOTS.userCreditsUsage;
                        }}
                      >
                        <ListItemIcon classes={{ root: 'list-item__icon' }} />
                        <ListItemText
                          className="list-item__text"
                          primary={t('general.navigation.credits-usage')}
                          primaryTypographyProps={{
                            variant: 'body2',
                          }}
                        />
                      </ListItem>
                    </>
                  )
                }
                  { isFeatureAccessible(APPLICATION_STATUSES.AVAILABLE)
                  && (
                    <Fragment>
                      <ListItem
                        button
                        component={NavLink}
                        to={getRootLink(PAGES_ROOTS.userFarms)}
                        activeClassName="list-item_selected"
                        classes={{
                          root: 'list-item',
                          gutters: 'list-item__gutters',
                        }}
                        isActive={(_match, location) => {
                          const pathname = location.pathname.slice(1);
                          return pathname === PAGES_ROOTS.userFarms;
                        }}
                      >
                        <ListItemIcon classes={{ root: 'list-item__icon' }} />
                        <ListItemText
                          className="list-item__text"
                          primary={t('general.navigation.farms')}
                          primaryTypographyProps={{
                            variant: 'body2',
                          }}
                        />
                      </ListItem>
                      <ListItem
                        button
                        component={NavLink}
                        to={getRootLink(PAGES_ROOTS.userIntegrations)}
                        activeClassName="list-item_selected"
                        classes={{
                          root: 'list-item',
                          gutters: 'list-item__gutters',
                        }}
                        isActive={(_match, location) => {
                          const pathname = location.pathname.slice(1);
                          return pathname.startsWith(PAGES_ROOTS.userIntegrations);
                        }}
                      >
                        <ListItemIcon classes={{ root: 'list-item__icon' }} >
                          {
                            notifications.user?.subMenus.includes('integrations')
                              && (
                                <div className={clsx('drawer__notification-dot', {
                                  'drawer__notification-dot_error': notifications.user.type === 'error',
                                })}
                                >
                                </div>
                              )
                          }
                        </ListItemIcon>
                        <ListItemText
                          className="list-item__text"
                          primary={t('general.navigation.integrations')}
                          primaryTypographyProps={{
                            variant: 'body2',
                          }}
                        />
                      </ListItem>
                      <ListItem
                        button
                        component={NavLink}
                        to={getRootLink(PAGES_ROOTS.userSettings)}
                        activeClassName="list-item_selected"
                        classes={{
                          root: 'list-item',
                          gutters: 'list-item__gutters',
                        }}
                        isActive={(_match, location) => {
                          const pathname = location.pathname.slice(1);
                          return pathname === PAGES_ROOTS.userSettings;
                        }}
                      >
                        <ListItemIcon classes={{ root: 'list-item__icon' }} />
                        <ListItemText
                          className="list-item__text"
                          primary={t('general.navigation.settings')}
                          primaryTypographyProps={{
                            variant: 'body2',
                          }}
                        />
                      </ListItem>
                    </Fragment>
                  )}
                </List>
              </Collapse>
            </Fragment>
          )
        }
      </List>
      {
        open
          ? (
            <Button
              classes={{
                root: 'drawer__toggle-button_big',
                startIcon: 'drawer__toggle-icon',
              }}
              startIcon={<CollapseMenuIcon />}
              onClick={handleToggleButtonClick}
            >
              {t('general.navigation.hide-menu')}
            </Button>
          )
          : (
            <IconButton
              classes={{
                root: 'drawer__toggle-button_small',
              }}
              onClick={handleToggleButtonClick}
            >
              <ExpandMenuIcon />
            </IconButton>
          )
      }
    </Drawer>
  );
};

export default AppDrawer;
