import React, {
  useMemo,
  useState,
} from 'react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import WorkIcon from '@material-ui/icons/Work';
import PhoneAndroidSharpIcon from '@material-ui/icons/PhoneAndroidSharp';
import clsx from 'clsx';

import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import type {
  AdditionalUserDataFields,
  AdditionalUserDataField,
} from '../../types/ui';
import ComboBox from '../../../../../components/ComboBox';
import {
  COMPANY_TYPE_I18N_KEY,
  CompanyType,
} from '../../helpers/constants/companyType';
import { getCountryHelperTexts } from '../../helpers/functions/country';
import {
  getMultilineHelperText,
  hasErrors,
} from '../../helpers/functions/authentication';
import { HelperTextType } from '../../helpers/constants/helperTexts';
import Checkbox from '../../../../../components/Checkbox';
import {
  getBrandName,
  getPolicyLink,
  getTermsLink,
} from '../../../../../helpers/functions/utils/appConfig';
import { USER_DATA_ERRORS } from '../../helpers/constants/errors';

import '../common/index.scss';
import './index.scss';

const formHelperInitialTexts = {
  country: [],
  companyType: [],
  acceptedTermsAndConditions: [],
  phoneNumber: [],
};

const AdditionalUserDataForm = ({
  userData,
  countries,
  onSave,
  onSaveUnknownError,
}: {
  userData: AdditionalUserDataFields;
  countries: Record<string, string>;
  onSave: (formState: AdditionalUserDataFields) => Promise<{ errors?: { message: string }[] }>;
  onSaveUnknownError: (error: { message: string }[]) => void;
}) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState(userData);
  const [formHelperTexts, setFormHelperTexts] = useState(
    formHelperInitialTexts,
  );

  const countryOptions = useMemo(() => {
    return Object.entries(countries).map(([value, title]) => ({
      value,
      title,
    }));
  }, [countries]);

  const selectedCountryOption = useMemo(() => {
    return countryOptions.find(({ value }) => value === formState.country);
  }, [countryOptions, formState.country]) ?? null;

  const companyTypeOptions = useMemo(() => {
    return Object.values(CompanyType)
      .map((value) => ({
        value,
        title: t(
          `authentication.additional-user-data.company-type.${COMPANY_TYPE_I18N_KEY[value]}`,
        ),
      }));
  }, [t]);

  const selectedCompanyTypeOption = useMemo(() => {
    return companyTypeOptions.find(
      ({ value }) => value === formState.companyType,
    );
  }, [companyTypeOptions, formState.companyType]) ?? null;

  const handleFieldChange = (
    field: AdditionalUserDataField,
    value: AdditionalUserDataFields[AdditionalUserDataField],
  ) => {
    const fieldHelperTexts = field === 'country'
      ? getCountryHelperTexts(value as string)
      : [];

    setFormState((prevFormState) => ({
      ...prevFormState,
      [field]: value,
    }));
    setFormHelperTexts((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: fieldHelperTexts,
    }));
  };

  const handleProceedClick = async () => {
    setFormHelperTexts(formHelperInitialTexts);

    const { errors } = await onSave(formState);

    if (!errors) {
      return;
    }

    const formErrors = errors
      .map((error) => USER_DATA_ERRORS.find((userDataError) => {
        return userDataError.regexp.test(error.message);
      }))
      .reduce(
        (acc, error) => (error
          ? {
            ...acc,
            [error.field]: [{
              label: t(error.helperTextI18NKey),
              type: HelperTextType.error,
            }],
          }
          : acc),
        {},
      );

    if (Object.values(formErrors).length) {
      setFormHelperTexts((prevFormErrors) => {
        return {
          ...prevFormErrors,
          ...formErrors,
        };
      });
    } else {
      onSaveUnknownError(errors);
    }
  };

  const proceedButtonDisabled = useMemo(() => {
    const hasRequiredFields = Object.values(formState)
      .every((value) => !!value);

    return !hasRequiredFields || hasErrors(formHelperTexts);
  }, [formHelperTexts, formState]);

  const getFieldErrorState = (field: AdditionalUserDataField) => {
    return formHelperTexts[field].some(({ type }) => {
      return type === HelperTextType.error;
    });
  };

  const brandName = getBrandName();

  return (
    <div className="authentication-panel">
      <Typography
        classes={{
          root: 'authentication-panel__description',
        }}
        variant="body2"
      >
        {t('authentication.additional-user-data.description')}
      </Typography>
      <form name="userData" className="authentication-panel__form additional-user-data-form">
        <ComboBox
          classes={{
            inputRoot: 'authentication-panel__input additional-user-data-form__input',
            outline: clsx({
              'additional-user-data-form__input_error': getFieldErrorState('country'),
            }),
          }}
          error={getFieldErrorState('country')}
          helperText={getMultilineHelperText(formHelperTexts.country)}
          placeholder={t('authentication.additional-user-data.fields.country')}
          options={countryOptions}
          value={selectedCountryOption}
          disableCloseOnSelect={false}
          getOptionSelected={(option, value) => option.value === value.value}
          onChange={(_event: React.ChangeEvent<{}>, selectedOption) => {
            handleFieldChange('country', selectedOption?.value ?? '');
          }}
          FormHelperTextProps={{
            classes: {
              root: 'authentication-panel__input-helper-text',
            },
          }}
          startAdornment={(
            <LanguageIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
            />
          )}
        />
        <ComboBox
          classes={{
            inputRoot: 'authentication-panel__input additional-user-data-form__input',
            outline: clsx({
              'additional-user-data-form__input_error': getFieldErrorState('companyType'),
            }),
          }}
          error={getFieldErrorState('companyType')}
          helperText={getMultilineHelperText(formHelperTexts.companyType)}
          placeholder={t(
            'authentication.additional-user-data.fields.company-type',
          )}
          options={companyTypeOptions}
          value={selectedCompanyTypeOption}
          disableCloseOnSelect={false}
          getOptionSelected={(option, value) => option.value === value.value}
          onChange={(_event: React.ChangeEvent<{}>, selectedOption) => {
            handleFieldChange('companyType', selectedOption?.value ?? '');
          }}
          FormHelperTextProps={{
            classes: {
              root: 'authentication-panel__input-helper-text',
            },
          }}
          startAdornment={(
            <WorkIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
            />
          )}
        />
        <TextField
          type="tel"
          value={formState.phoneNumber}
          placeholder={t('authentication.additional-user-data.fields.phone-number')}
          InputProps={{
            classes: {
              root: 'authentication-panel__input',
            },
            startAdornment: (
              <PhoneAndroidSharpIcon
                fontSize="small"
                className="authentication-panel__input-icon authentication-panel__input-icon_start"
              />
            ),
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleFieldChange('phoneNumber', event.target.value);
          }}
        />
        <div className="additional-user-data-form__terms-and-conditions">
          <Checkbox
            classes={{
              root: 'additional-user-data-form__terms-and-conditions-checkbox',
            }}
            value={formState.acceptedTermsAndConditions ? 2 : 0}
            onClick={() => {
              handleFieldChange('acceptedTermsAndConditions', !formState.acceptedTermsAndConditions);
            }}
          />
          <Trans
            parent="div"
            className="additional-user-data-form__terms-and-conditions-statement"
            i18nKey="authentication.additional-user-data.terms-and-conditions"
          >
            I agree with the
            <a className="link" href={getTermsLink()} target="_blank">
              Terms & Conditions
            </a>
            and
            <a className="link" href={getPolicyLink()} target="_blank">
              Privacy policy
            </a>
            to start working with {{ brandName }}.
          </Trans>
        </div>
        <Button
          className="authentication-panel__button"
          variant="contained"
          color="primary"
          disabled={proceedButtonDisabled}
          onClick={handleProceedClick}
        >
          {t('general.controls.proceed')}
        </Button>
      </form>
    </div>
  );
};

export default AdditionalUserDataForm;
