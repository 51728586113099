import React, { useState } from 'react';

import AdditionalUserDataForm from '../../components/AdditionalUserDataForm';
import Panel from '../../components/AuthenticationPanel';
import { errorNotify } from '../../../../notifications/helpers/functions/notify';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { CustomError } from '../../../../../helpers/functions/utils/errorHandling';
import { selectAdditionalUserData } from '../../../../user/userSelectors';
import { updateAdditionalUserData } from '../../../../user/userSlice';
import { AdditionalUserDataFields } from '../../types/ui';
import useCountriesList from '../../hooks/useCountriesList';

const AdditionalUserDataPanel = () => {
  const [saveInProgress, setSaveInProgress] = useState(false);
  const userData = useAppSelector(selectAdditionalUserData);
  const dispatch = useAppDispatch();

  const { countries, countriesLoading } = useCountriesList();

  const handleSave = async (data: AdditionalUserDataFields) => {
    setSaveInProgress(true);
    // TODO: fix typings when userSlice refactored
    const { payload } = await dispatch((updateAdditionalUserData as any)(data));

    setSaveInProgress(false);

    return payload;
  };

  const handleSaveUnknownError = (error: { message: string }[]) => {
    errorNotify({
      error: new CustomError(
        '[Additional User Info] Unable to fetch the list of countries.',
        {
          cause: error,
        },
      ),
      dispatch,
    });
  };

  return (
    <Panel loading={saveInProgress || countriesLoading}>
      <AdditionalUserDataForm
        userData={userData}
        countries={countries}
        onSave={handleSave}
        onSaveUnknownError={handleSaveUnknownError}
      />
    </Panel>
  );
};

export default AdditionalUserDataPanel;
